.nav-active * {
    color: #3399FF;
}

a {
    text-decoration: none;
}

.loading-icon-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: max-content; 
    height: 70vh;
}

iframe {
    color-scheme: auto;
}

.j-url:hover {
    cursor: pointer;
}